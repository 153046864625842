import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import styles from "./styles";
import HTMLContent from "../ContentJustified";
import TechnologyCards from "../TechnologyCards";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { QontoStepIcon } from "../StepperStyles";
import SwipeableViews from 'react-swipeable-views';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
  },
}))(LinearProgress);

const CourseModules = ({ smDown, content, technologies, classes }) => {
  return (
    <React.Fragment>
      {content && (
        <TimeLine
          smDown={smDown}
          classes={classes}
          modules={content.content}
          technologies={technologies}
        />
      )}
    </React.Fragment>
  );
};


const TimeLine = ({ smDown, classes, modules, technologies }) => {
  const [selected, setSelected] = useState(0);
  const theme = useTheme();
  const value = (selected + 1) * 33.3;

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };
  const handleChangeIndex = (index) => {
    setSelected(index);
  };
  const handleNext = (e) => {
    e.preventDefault();
    setSelected((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setSelected((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Container maxWidth="lg" className={classes.container}>

      <BorderLinearProgress variant="determinate" value={value} />

      <Stepper
        orientation={smDown ? "vertical" : "horizontal"}
        activeStep={selected}>
        {modules.map((item, index) => {
          const stepProps = {};
          const labelProps = {};

          if (selected >= index) {
            stepProps.completed = true;

          }
          if (selected === index) {
            stepProps.completed = true;
            labelProps.optional = <Typography
              variant="h6">{item.time}</Typography>;
          }
          return (
            <Step key={index} {...stepProps}
              onClick={() => handleChange(null, index)}
              classes={{ root: classes.step }}

            >


              <StepLabel
                StepIconComponent={QontoStepIcon}
                classes={{ label: classes.textColorPrimary }}

                {...labelProps} >{item.module.toUpperCase()}</StepLabel>
              {selected !== index && <Button
                color="primary"
                variant="outlined"
                size="small"
                disabled={selected === index}
              >
                select</Button>}

            </Step>
          );
        })}
      </Stepper>


      <div>

        <TechnologyCards
          content={technologies}
          selected={selected}
        />
        {smDown && <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={selected}
          className={classes.root}
          nextButton={
            <Button color="primary" size="small" onClick={handleNext} disabled={selected === modules.length - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button color="primary" size="small" onClick={handleBack} disabled={selected === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />}
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selected}
          onChangeIndex={handleChangeIndex}
        >
          {modules.map((item, index) =>
            <div key={index} >

              {selected === index && <HTMLContent content={item.description.childMarkdownRemark.html} />}
            </div>
          )}

        </SwipeableViews>


      </div>


    </Container>
  );
};

export default withStyles(styles)(CourseModules);
