const styles = (theme) => ({
  "@global": {

    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  gridContainer: {

    marginTop: 48,
  },
  gridContainerMobile: {
    width: "100%",
    marginTop: 48,
  },
  card: {
    height: 70,
    [theme.breakpoints.down("md")]: {
      height: 50,
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    // cursor: "pointer",
    position: "relative",
  },
  hiddenCard: {
    height: 70,
    [theme.breakpoints.down("md")]: {
      height: 50,
    },
    // height: 40,
    // [theme.breakpoints.down("md")]: {
    //   height: 30,
    // },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.3,
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    height: 230,
    background: "rgba(0, 0, 0, 0.8)",
    opacity: 0,
    width: "100%",
    color: "white",
    fontSize: "20px",
    padding: "20px",
    borderRadius: "50px",
  },
  "@keyframes border": {
    from: {
      border: "no-border",
    },
    to: {
      borderWidth: "4px",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
  },
  cardBack: {
    height: 250,
    cursor: "pointer",
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  cardLength: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default styles;
