import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HeaderSection from "../components/HeaderSection";
import CourseModules from "../components/CourseModules";
import Pricing from "../components/Pricing";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SEO from "../components/SEO";
import { Grid, Typography, Button } from "@material-ui/core";
import StartCards from "../components/StartCards";
import DailyTimeline from "../components/DailyTimeline";
import Section from "../components/Section";
import SectionDecoration from "../components/SectionDecoration";

const CoursePageTemplate = ({
  course,
  heading,
  subheading,
  intro,
  syllabus,
  modules,
  title,
  location,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Section>
        <HeaderSection
          center={true}
          header={heading}
          subheader={subheading}
          location={location}
        />
        <CourseModules
          content={modules}
          technologies={intro}
          smDown={smDown}
        />


      </Section>
      <SectionDecoration color={theme.palette.common.white}
        bgColor={theme.palette.primary.main}>
        <HeaderSection header="Next courses" />

        <StartCards location={location} course={course} all={false} jsonLd={false} />
      </SectionDecoration>
      <Section>
        <HeaderSection header="Daily Life" location={location} subheader="We use a project-based approach to learning, in which you will be assigned projects with a minimum viable product to be presented at the end. We follow the Agile Scrum Methodology, which is a project management system used very commonly in the tech industry." />
        <DailyTimeline smDown={smDown} />
      </Section>
      <Pricing location={location} small={mdDown ? true : false} />

      <Section>
        <Typography
          component="h5"
          align="center"

          variant="h5"
        // style={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          Download our course syllabus
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
        > <Button
          align="center"
          href={syllabus}
          target="_blank"
          variant="contained"
          color="primary"

        // className={classes.button}
        // underline="none"
        // name="course"
        // value="unsure"
        // disabled={
        //   syllabusForm?.firstname &&
        //     syllabusForm?.lastname &&
        //     syllabusForm?.email &&
        //     isAgreed
        //     ? false
        //     : true
        // }
        >
            {title} Syllabus
          </Button>
        </Grid>
      </Section>
      {/* <CourseSpecifications
        xsDown={xsDown}
        content={specifications}
        html={html}
        skillUp={false}
      /> */}
    </>
  );
};

CoursePageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  info: PropTypes.array,
  modules: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.array,
  }),
  specifications: PropTypes.shape({}),
  methodology: PropTypes.shape({}),
  html: PropTypes.string,
};

const CoursePage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={
          "You ideas come to reality with us: you choose the project, we give you help on how to build it."
        }
      />

      <CoursePageTemplate
        title={frontmatter.title}
        course={frontmatter.course}
        syllabus={frontmatter.syllabus}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
        main={frontmatter.main}
        info={frontmatter.info}
        modules={frontmatter.modules}
        specifications={frontmatter.specifications}
        html={html}
        methodology={frontmatter.methodology}
        location={location}
      />
    </Layout>
  );
};

CoursePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export default CoursePage;

export const coursePageQuery = graphql`
  query CoursePageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        course
        syllabus
        subheading
        info {
          title
         
          description
        }
        intro {
          blurbs {
            image {
              publicURL
            }
            text
            title
            module
          }
        }
        modules {
          heading
          content {
            time
            module
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        specifications {
          title
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
          }
        }
      }
      html
    }
  }
`;
