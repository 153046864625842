import React from 'react';
import { withStyles } from '@material-ui/core/styles';


import styles from './styles';

const Section = ({ children, classes }) => {


  return (

    <div
      className={classes.wrapper}
    >{children}</div>


  );

}

export default withStyles(styles)(Section);
