import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";

const TechnologyCards = ({ content, selected, classes }) => {
  const { blurbs } = content;
  return (
    <Grid
      container
      className={classes.gridContainer}
      justifyContent="space-evenly"
    >

      {blurbs
        // .filter((blurb, index) => selected >= blurb.module)
        .map((item, index) => (


          <div key={index} >
            <img
              className={`${selected >= item.module ? classes.card : classes.hiddenCard
                }`}
              src={item?.image?.publicURL}
              // style={{ width: "50px", height: "50px" }}

              alt={item.title}
            />

            <Typography
              component="h6"
              variant="h6"
              style={{ opacity: `${selected >= item.module ? 1 : 0.3}` }}
              align="center"
            >
              {item.title}
            </Typography>
          </div>



        ))}
    </Grid>
  );
};



export default withStyles(styles)(TechnologyCards);
