import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import GroupIcon from '@material-ui/icons/Group';
import SchoolIcon from '@material-ui/icons/School';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from "./styles";



const DailyTimeline = ({ classes, smDown }) => {

  return (
    <Timeline
      align={!smDown ? "alternate" : "left"}
      className={classes.root}
    >
      <TimelineItem>
        <TimelineOppositeContent
          classes={{
            root: classes.oppositeContent
          }}>
          <Typography variant="body2" color="textPrimary">
            08:00
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator >
          <TimelineDot variant="outlined" >
            <ArrowDropDownIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>

          Campus opens</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent classes={{
          root: classes.oppositeContent
        }}>
          <Typography variant="body2" color="textPrimary">
            09:30
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <GroupIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent >
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6" component="h6">
              Stand up meeting
            </Typography>
            <Typography variant='subtitle1'
              component="p"
            >We start our day with a stand-up meeting where each student will set goals for the day</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent classes={{
          root: classes.oppositeContent
        }}>
          <Typography variant="body2" color="textPrimary">
            10:00
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <SchoolIcon color='primary' />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6" component="h6">
              Lesson
            </Typography>
            <Typography variant='subtitle1'
              component="p">You will have lessons in the form of “spikes”, live practical demonstrations by your mentors, of technologies and concepts that you will be required to include in your projects</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent classes={{
          root: classes.oppositeContent
        }}>
          <Typography variant="body2" color="textPrimary">
            12:00
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6" component="h6">
              Learn by doing
            </Typography>
            <Typography variant='subtitle1'
              component="p">You are given time to work independently, and you are also encouraged to spend time improving your research skills by conducting independent research. Mentor support is available throughout the day, you can book 30 minute appointments as often as you need!</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent classes={{
          root: classes.oppositeContent
        }}>
          <Typography variant="body2" color="textPrimary">
            16:30
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <HowToRegIcon color='primary' />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6" component="h6">
              Check out
            </Typography>
            <Typography variant='subtitle1'
              component="p">We finish our day with a check-out meeting to discuss your progress for the day, whether you achieved your goals, and any blocks you are experiencing</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent classes={{
          root: classes.oppositeContent
        }}>
          <Typography variant="body2" color="textPrimary">
            18:00
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
            <RepeatIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          Campus closes
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export default withStyles(styles)(DailyTimeline);