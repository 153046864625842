const styles = (theme) => ({
  container: {
    // width: "65%",
    height: "100%",
    // minHeight: "100vh",
  },
  containerMobile: {
    width: "100%",
    height: "100%",
  },
  timeline: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: "relative",
    width: "100%",
  },
  textColorPrimary: {
    fontFamily: "Lovelo",
    fontSize: "1rem",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: ".8rem",
    // },

    cursor: "pointer",
  },

  backgroundTimeline: {
    position: "absolute",
    background: theme.palette ? theme.palette.grey[300] : "black",
    maxWidth: "100%",
    width: "100%",
    height: "10px",
    borderWidth: 0,
    borderRadius: "5px",
    color: theme.palette ? theme.palette.common.white : "white",
  },
  redTimeline: {
    position: "absolute",
    background: theme.palette ? theme.palette.primary.main : "#F26363",
    height: "10px",
    borderWidth: 0,
    borderRadius: "5px",
    color: theme.palette ? theme.palette.secondary.main : "#F26363",
  },
  timelineContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  timelineButton: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 5,
    margin: 15,
    padding: 15,
    cursor: "pointer",
    backgroundColor: theme.palette ? theme.palette.grey[300] : "#F26363",
    "&:hover": {
      backgroundColor: theme.palette ? theme.palette.grey[500] : "#F26363",
    },
  },
  timelineButtonDarker: {
    margin: 15,
    cursor: "pointer",
    padding: 15,
    color: theme.palette.common.white,
    backgroundColor: theme.palette ? theme.palette.primary.main : "#F26363",
    "&:hover": {
      backgroundColor: theme.palette ? theme.palette.primary.dark : "#F26363",
    },
  },
  moduleContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    backgroundColor: theme.palette ? theme.palette.grey[300] : "#F26363",
    borderRadius: 5,
  },
  module: {
    margin: 0,
    padding: 20,
    width: 1000,
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
  },
  animationIn: {
    animationName: "$moduleAnimationIn",
    animationDuration: "0.5s",
  },
  animationOut: {
    animationName: "$moduleAnimationOut",
    animationDuration: "0.5s",
  },
  "@keyframes moduleAnimationIn": {
    "0%": {
      opacity: 0,
    },
    "20%": {
      opacity: 0.2,
    },
    "40%": {
      opacity: 0.4,
    },
    "60%": {
      opacity: 0.6,
    },
    "80%": {
      opacity: 0.8,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes moduleAnimationOut": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0.8,
    },
    "40%": {
      opacity: 0.6,
    },
    "60%": {
      opacity: 0.4,
    },
    "80%": {
      opacity: 0.2,
    },
    "100%": {
      opacity: 0,
    },
  },
  step: {
    // borderStyle: "solid",
    // borderWidth: 1,
    // 

    // "&:hover": {
    //   borderStyle: "solid",
    //   borderColor: theme.palette.primary.main,
    //   borderPadding: 20,
    //   // padding: theme.spacing(2),
    // },
  },
  outlinedPrimary: {
    // color: "red",
    // "&:active": {
    //   color: "green"
    // }
  },
  moduleOptions: {
    padding: 0,
    textAlign: "justify",
    "& ul": {
      listStyleType: "circle",
      marginLeft: 15,
      marginRight: 15,
      [theme.breakpoints.up("md")]: {
        marginLeft: 10,
        marginRight: 0,
      },
    },
    "& li": {
      fontSize: "0.9rem",
      marginLeft: 35,
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
        marginLeft: 10,
        marginRight: 0,
      },
    },
    "& p": {
      fontSize: "0.9rem",
      marginBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
  },
});

export default styles;
