import { alpha } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    maxWidth: 1200,
  },
  paper: {
    padding: theme.spacing(2, 1),

    backgroundColor: alpha(theme.palette.grey[300], 0.3),
    // background: `linear-gradient(${alpha(theme.palette.paper.main, 0.3)}, ${alpha(theme.palette.primary.main, 0.5)}45%)`,


  },
  oppositeContent: {
    [theme.breakpoints.down("sm")]: {
      flex: 0,

    },

    [theme.breakpoints.down("xs")]: {
      width: 60,
    },
  },

});
export default styles;
